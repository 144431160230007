header {
   height: 100vh;
   padding-top: 7rem;
   overflow: hidden;
}

.header__container {
    text-align: center;
    height: 100%;
    position: relative;
}

/* =============== CTA =============== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ========== HEADER SOCIALS ============ */
.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
}

.header__socials::after {
    content: "";
    width: 1px;
    height: 2rem;
    background: var(--color-primary);
}

/* ============== Me ================ */
.me {
    background: linear-gradient(var(--color-primary), transparent);
    width: 22rem;
    height: 30rem;
    position: absolute;
    left: calc(50% - 11rem);
    margin-top: 4rem;
    border-radius: 12rem 12rem 0 0;
    overflow: hidden;
    padding: 2rem 1.5rem 1.5rem 1.5rem;
}

/* ============= SCROLL DOWN ================ */
.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    transform: rotate(90deg);
    font-weight: 300;
    font-size: 0.9rem;
}

/* ============== MEDIA QUERIES (MEDIUM DEVICES) ============== */
@media screen and (max-width: 1024px) {
    header {
        height: 80vh;
    }
}

/* ============== MEDIA QUERIES (SMALL DEVICES) ============== */
@media screen and (max-width: 600px) {
    header {
        height: auto;
        padding-top: 2rem; /* Adjust padding for smaller screens */
        overflow: visible; /* Allow content overflow */
    }

    .header__socials, .scroll__down {display: none;}

    .me {
        width: 70%; /* Reduce the image size to 50% of the screen width */
        max-width: 200px; /* Add a maximum width to limit the size */
        height: auto; /* Let the image maintain its aspect ratio */
        position: relative; /* Change from absolute to relative positioning */
        left: 50%; /* Move the image 50% from the left edge */
        transform: translateX(-50%); /* Center the image horizontally */
        margin-top: 2rem; /* Adjust margin-top for spacing */
        border-radius: 12rem 12rem 0 0; /* Add curved corners only at the top */
        padding: 1rem; /* Adjust padding as needed */
    }
}